import "./ModalDrilldownGroups.scss";

import React, { useEffect, useState } from "react";
import { Spin, Table, Tabs } from "antd";
import { withTranslation } from "react-i18next";
import OutputNumber from "../../Number/Number";

function ModalDrilldownGroups(props) {
  const { data = [], element, groups = [], loading = false, t } = props;

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: t('name'),
      render: (text) => (<p>{text}</p>)
    },
    {
      dataIndex: 'value',
      key: 'value',
      title: t('result'),
      render: (text) => (<p>{text}</p>),
      width: '40%'
    }
  ];

  return (
    <div className={'mdg-wrapper'}>
      <div className={'mdg-title'}>
        {element.title}
      </div>

      <div className={'mdg-content'}>
        {loading ? <Spin size={'large'} /> : !!groups && (
          <Tabs defaultActiveKey="1" tabPosition={'left'} items={groups.map(g => {
            const groupData = data?.[g.id] ?? [];
            const groupValue = groupData['value'];
            const orgData = g.organizations.map(o => {
              const value = groupData[o.id] ?? '-';
              return {
                name: o.name,
                value: element.type === 'number' ? <OutputNumber element={element} value={value} /> : value
              };
            });

            return {
              children: (
                <div className={'mdg-tab'}>
                  <div className={'mdg-tab-header'}>
                    <p className={'mdg-tab-title'}>{g.name}</p>
                    <p className={'mdg-tab-value'}>{`${t('average')}: `}{element.type === 'number' ? <OutputNumber element={element} value={groupValue} /> : groupValue}</p>
                  </div>
                  <div className={'mdg-tab-content'}>
                    <Table columns={columns} dataSource={orgData} pagination={false} scroll={{y: `${(orgData.length > 15 ? 15 : orgData.length) * 37}px`}} style={{ width: '100%' }}/>
                  </div>
                </div>
              ),
              key: g.id,
              label: g.name
            };
          })}/>
        )}
      </div>

      

      {/* {loading ? <Spin size={'large'} /> : !!data && data?.map((g, i) => (
        <div key={i} style={{marginTop: "1.5em", width: "100%"}}>
          <div style={{display: "flex", justifyContent: "space-between", marginBottom: ".5em", width: "100%"}}>
            <p style={{fontWeight: "600", margin: 0}}>{g.name}</p>
            <p style={{margin: 0}}>{g.result}</p>
          </div>

          <Table bordered columns={columns} dataSource={g.data} pagination={false} style={{ width: '100%' }} />
        </div>
      ))} */}
    </div>
  );
}

export default withTranslation('benchmark')(ModalDrilldownGroups);