import { Checkbox, Form, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";
import Validation from "../../../Validation";

export default function CheckboxElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(!!(submission?.data?.find((d) => d.key === element.webform_key)?.value));

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;
    const isDisabled = (multiset && data?.is_default_value) || readonly || (submission?.completed && !isChild) || disabled;

    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                        )}

                        <Form.Item>
                            <Checkbox 
                                checked={value}
                                disabled={isDisabled || data?.nvh || data?.nvt} 
                                key={element.webform_key} 
                                onChange={(e) => {setValue(e.target.checked); onChange(submission.organisation_id, element.webform_key, e.target.checked ? 1 : 0);}}/>
                        </Form.Item>

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                                                                                                                    
                        <Validation elementKey={element.webform_key} errors={submission?.validation?.errors} warnings={submission?.validation?.warnings}/>
                    </div>
                </Form>
            )}
        </>
    );
}