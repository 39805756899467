import { DatePicker, Form } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";
import Validation from "../../../Validation";

export default function DateElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const multiset = !!(submission?.parent_organisation); 
    const submittable = !!submission && submission.progress.basic.filled === submission.progress.basic.count_required;
    const isDisabled = (multiset && data?.is_default_value) || submittable || (submission?.completed && !isChild) || disabled;

    return (
        <>
            <Form>
                <div className="qs-area">
                    {!isChild && (
                        <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                    )}

                    <Form.Item>
                        <DatePicker 
                            defaultValue={value}
                            disabled={isDisabled || data?.nvh || data?.nvt} 
                            onChange={(d, ds) => {setValue(ds); onChange(submission?.organisation_id, element.webform_key, ds);}}
                            value={value}/>
                    </Form.Item>

                    {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                                                                    
                    <Validation elementKey={element.webform_key} errors={submission?.validation?.errors} warnings={submission?.validation?.warnings}/>
                </div>
            </Form>
        </>
    );
}