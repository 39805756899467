// Function that renders a number with the formatting defined in the element.
// If it can't be converted to a number, the value itself will be returned without conversion.
// This is to support values like 'nvt', 'nvh' and '-' which can occur in the dataset.
export default function OutputNumber({element, value}) {
  if (value === undefined || value  === null)
    return null;
  
  if (isNaN(Number(value)))
    return (value);

  // Convert to number
  value = Number(value);

  // Set decimal places
  value = value.toFixed(Number(element.decimal_places ?? 2));

  // Convert number to string
  value = value.toString();

  // Replace the decimal separator and insert a custom thousand separator
  if (element.numeric_decimal !== null && element.numeric_thousand !== null) {
    let values = value.split('.');

    value = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, element.numeric_thousand);

    if (values.length > 1)
      value += `${element.numeric_decimal}${values[1]}`;
  }

  return (value);
}