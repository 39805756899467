import { Button, Form, Select, Spin } from "antd";
import Help from "../../../Help";
import More from "../../../More";
import React, { useEffect, useState } from "react";
import Validation from "../../../Validation";

export default function SelectElement({active, disabled, element, isChild = false, loadingSubmission, onChange, submission}) {
    const [data, setData] = useState(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
    const [value, setValue] = useState(submission?.data?.find((d) => d.key === element.webform_key)?.value === '0' ? 0 : submission?.data?.find((d) => d.key === element.webform_key)?.value);

    useEffect(() => {
        if (!loadingSubmission) {
            setData(submission?.data?.find((d) => d.key === element.webform_key) ?? null);
            setValue(submission?.data?.find((d) => d.key === element.webform_key)?.value === '0' ? 0 : submission?.data?.find((d) => d.key === element.webform_key)?.value);
        }
    }, [loadingSubmission, submission?.data?.find((d) => d.key === element.webform_key)?.value]);

    const isCalculation = (!!element.is_multilevel && !!submission && !!submission?.child_organisations?.length) || !!element.is_autocalculated;
    const isOverwritten = !!(data?.is_overwritten);
    const multiset = !!(submission?.parent_organisation);
    const readonly = Object.hasOwn(element, 'readonly') ? !!element.readonly : false;
    const isDisabled = (multiset && data?.is_default_value) || readonly || (submission?.completed && !isChild) || disabled;
    
    return (
        <>
            {loadingSubmission ? (<Spin/>) : (
                <Form>
                    <div className="qs-area">
                        {!isChild && (
                            <Help active={active} help={element.help} required={element.required} title={element.title} nvh={data?.nvh} nvt={data?.nvt}/>
                        )}

                        <Form.Item>
                            <Select
                                className="questionnairesSelect"
                                defaultValue={value}
                                disabled={isDisabled || data?.nvh || data?.nvt}
                                key={element.webform_key}
                                onChange={(v) => {setValue(v); onChange(submission.organisation_id, element.webform_key, v)}}
                                style={{ width: "100%" }}
                                value={value}>
                                {(element.options ?? []).map((opt) => (
                                    <Select.Option key={opt.key} value={opt.key}>{opt.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {!readonly && isCalculation && !!isOverwritten && (
                            <Button>Waarde berekenen</Button>
                        )}

                        {!isChild && <More more={element.more} moreTitle={element.more_title} />}
                        
                        <Validation elementKey={element.webform_key} errors={submission?.validation?.errors} warnings={submission?.validation?.warnings}/>
                    </div>
                </Form>
            )}
        </>
    );
}